import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import '../../styles/footer.css'; // Ensure to create a separate CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="py-4">
                    <Col md={4} className="footer-brand">
                        <h3>Crafted Web Creations</h3>
                        <p>Your partner in crafting digital solutions.</p>
                    </Col>
                    <Col md={4} className="footer-links">
                        <h5>Quick Links</h5>
                        <ul>
                            <li><a href="#home">Home</a></li>
                            <li><a href="#why-you-need">Why You Need A Website</a></li>
                            <li><a href="#services">Services</a></li>
                            <li><a href="#why-us">Why Us</a></li>
                            <li><a href="#how-we-operate">How We Operate</a></li>
                            <li><a href="#contact-us">Contact Us</a></li>
                        </ul>
                    </Col>
                    <Col md={4} className="footer-contact">
                        <h5>Contact Us</h5>
                        <p>Email: <a href="mailto:craftedwebcreations@gmail.com">craftedwebcreations@gmail.com</a></p>
                        <div className="social-icons">
                            <a href="#" aria-label="Facebook"><FaFacebook /></a>
                            <a href="#" aria-label="Twitter"><FaTwitter /></a>
                            <a href="#" aria-label="Instagram"><FaInstagram /></a>
                            <a href="#" aria-label="LinkedIn"><FaLinkedin /></a>
                        </div>
                        <Link to={"/attr"}>Attributions</Link>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center py-3">
                        <p>&copy; {new Date().getFullYear()} Crafted Web Creations. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
