import React, { useState } from 'react';
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import emailjs from 'emailjs-com';
import '../styles/home.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 

const WhyYouNeed = () => {
    const reasons = [
        {
            title: 'First Impressions',
            description: 'Your website is often the first interaction potential customers have with your brand, making a professional design crucial for building trust.',
        },
        {
            title: 'User Experience',
            description: 'A well-structured site enhances navigation and accessibility, making it easier for visitors to find what they need.',
        },
        {
            title: 'SEO and Visibility',
            description: 'An optimized website attracts organic traffic, boosting your visibility in search engines.',
        },
        {
            title: 'Conversion Rates',
            description: 'A compelling website guides visitors toward taking action, like making a purchase or signing up for updates.',
        },
        {
            title: 'Brand Identity',
            description: 'It showcases your brand’s personality, helping you stand out in a competitive market.',
        },
        {
            title: 'Mobile Compatibility',
            description: 'A responsive design ensures your site functions well on all devices, catering to the rise of mobile browsing.',
        },
        {
            title: 'Analytics and Insights',
            description: 'Good websites provide data on visitor behavior, informing your marketing strategies for better results.',
        },
    ];

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <section id="why-you-need" className="my-5">
            <Container>
                <h1 className="text-center services-title" style={{ color: '#007bff', fontWeight: 'bold' }}>
                    Why You Need a Great Website
                </h1>
                <Slider {...settings}>
                    {reasons.map((reason, index) => (
                        <div key={index} className="text-center">
                            <h4 style={{ color: '#007bff' }}>{reason.title}</h4>
                            <p>{reason.description}</p>
                        </div>
                    ))}
                </Slider>
            </Container>
        </section>
    );
};



const ContactUs = () => {
    const { ref, inView } = useInView({ threshold: 0.1 });
    
    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        config: { tension: 200, friction: 20 },
    });

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.send('service_tl8ngpp', 'template_6wrgtm9', formData, 'UDWJISbUqYfRyfEE9')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                // Reset form after submission
                setFormData({ name: '', email: '', message: '' });
            })
            .catch((err) => {
                console.error('Failed to send email. Error:', err);
            });
    };

    return (
        <section id="contact-us" className="my-5" ref={ref}>
            <Container>
                <div className="contact-title text-center mb-4">
                    <h1>Contact Us</h1>
                </div>
                <Row className="d-flex align-items-stretch">
                    <Col md={6}>
                        <animated.div style={animation}>
                            <Form onSubmit={handleSubmit} className="contact-form">
                                {/* Form Fields */}
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Enter your name" 
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required 
                                        className="rounded-0"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Enter your email" 
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required 
                                        className="rounded-0"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formMessage">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3} 
                                        placeholder="Write your message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required 
                                        className="rounded-0"
                                    />
                                </Form.Group>
                                <div className="text-center mt-3">
                                    <Button variant="primary" type="submit" className="rounded-0">
                                        Send Message
                                    </Button>
                                </div>
                            </Form>
                        </animated.div>
                    </Col>
                    <Col md={6} style={{justifyContent: "center"}} className="text-center">
                        <animated.div style={animation}>
                            <div>
                                <iframe style={{ width: '50%', height: '50%', border: 'none' }} src="https://lottie.host/embed/aebe8cfd-bf0d-41d5-abc8-8cfe5efde117/b5KrE6Ac7p.json"></iframe>
                            </div>
                            <p>If you have any questions or need further assistance, feel free to contact us.</p>
                            <p>Email: <a href="mailto:craftedwebcreations@gmail.com">craftedwebcreations@gmail.com</a></p>
                        </animated.div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

const WhyUs = () => {
    const { ref, inView } = useInView({
        threshold: 0.1, // Trigger when 10% of the component is visible
    });

    const Animation = (index) => {
        return useSpring({
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0)' : 'translateY(20px)',
            config: { tension: 200, friction: 20 },
            delay: index * 200, // Delay for each item
        });
    };

    const reasons = [
        {
            title: 'Personalized Consultations',
            description: 'We take the time to meet with you to understand exactly what you want!',
            icon: 'https://cdn-icons-png.flaticon.com/128/17717/17717340.png',
        },
        {
            title: 'Custom Source Code',
            description: 'We tailor our source code to ensure you\'re not restricted to specific hosting and can easily make edits.',
            icon: 'https://cdn-icons-png.flaticon.com/128/9679/9679801.png',
        },
        {
            title: 'Transparent Pricing',
            description: 'We offer fair prices and provide a detailed quote after understanding your exact requirements.',
            icon: 'https://cdn-icons-png.flaticon.com/128/543/543135.png',
        },
        {
            title: 'Express Delivery',
            description: 'We work fast to get our work back to you!',
            icon: 'https://cdn-icons-png.flaticon.com/128/2203/2203124.png',
        },
    ];
    
    return (
        <section id="why-us" className="my-5" ref={ref}>
            <Container>
                <div className="why-us-title text-center mb-4">
                    <h1>Why Us?</h1>
                </div>
                <Row className="mt-5 d-flex align-items-stretch">
                    {reasons.map((item, index) => {
                        const animation = Animation(index);
                        return (
                            <Col xs={12} md={6} lg={3} key={index} className="mb-4">
                                <animated.div style={animation} className="service-card h-100 text-center">
                                    <div className="icon-container mb-3">
                                        <img
                                            className="why-us-item-icon"
                                            src={item.icon}
                                            alt={`${item.title} Icon`}
                                        />
                                    </div>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                </animated.div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};

const HowWeOperate = () => {
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const Animation = (index) => {
        return useSpring({
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0)' : 'translateY(20px)',
            config: { tension: 200, friction: 20 },
            delay: index * 200,
        });
    };

    const steps = [
        { number: '1', title: 'Reach Out', description: 'Share your name, email, and a brief message about yourself and your business.' },
        { number: '2', title: 'Initial Contact', description: 'We will promptly get in touch to schedule a meeting and discuss your needs in detail.' },
        { number: '3', title: 'Receive a Quote', description: 'Following our discussion, we\'ll provide you with a customized quote based on your requirements.' },
        { number: '4', title: 'Project Execution', description: 'Once you approve the quote, we\'ll start working on your project and regularly check in to ensure it meets your expectations.' },
        { number: '5', title: 'Delivery', description: 'We will send you the source code and offer hosting and management options.' },
    ];

    return (
        <section id="how-we-operate" className="my-5" ref={ref}>
            <Container>
                <div className="hwo-title text-center mb-4">
                    <h1>Our Process</h1>
                </div>
                <Row className="mt-5 d-flex align-items-stretch">
                    {steps.map((step, index) => {
                        const animation = Animation(index);
                        return (
                            <Col xs={12} md={6} lg={4} className="process-step mb-4" key={index}>
                                <animated.div style={animation} className="text-center step-content">
                                    <div className="step-number">{step.number}</div>
                                    <h2>{step.title}</h2>
                                    <p>{step.description}</p>
                                </animated.div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};


const Home = () => {
    const { ref, inView } = useInView({
        threshold: 0.1, // Trigger when 10% of the component is visible
    });

    const Animation = (index) => {
        return useSpring({
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0)' : 'translateY(20px)',
            config: { tension: 200, friction: 20 },
            delay: index * 100, // Delay for each service
        });
    };

    const IntroAnimation = useSpring({
        from: { opacity: 0, transform: 'translateY(20px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        config: { tension: 200, friction: 20 },
        delay: 200,
    });

    const services = [
        {
            title: 'Web Development',
            description: 'Custom websites built with modern technologies.',
            tooltip: 'We use the latest frameworks and tools to create responsive, fast-loading websites tailored to your needs.',
            icon: 'https://cdn-icons-png.flaticon.com/128/1927/1927746.png',
        },
        {
            title: 'SEO Optimization',
            description: 'Boost your site\'s visibility on search engines.',
            tooltip: 'Our SEO experts optimize your site structure, keywords, and content to improve rankings and attract organic traffic.',
            icon: 'https://cdn-icons-png.flaticon.com/128/3579/3579143.png',
        },
        {
            title: 'Branding',
            description: 'Create a unique identity for your business.',
            tooltip: 'We develop cohesive brand strategies, including logos and color schemes, to enhance your market presence.',
            icon: 'https://cdn-icons-png.flaticon.com/128/7832/7832467.png',
        },
        {
            title: 'Hosting and Management',
            description: 'Reliable hosting solutions with expert management.',
            tooltip: 'We offer secure, scalable hosting services along with continuous management and support to keep your website running smoothly.',
            icon: 'https://cdn-icons-png.flaticon.com/128/4380/4380600.png',
        },
    ];

    return (
        <Container fluid className="hero-section">
            <animated.div id="home" style={IntroAnimation}>
                <Row className="align-items-center">
                    <Col md={6} className="text-center text-md-left">
                        <h1 className="hero-title">Transform Your Online Presence</h1>
                        <p className="hero-description">
                            Welcome to Crafted Web Creations! We design stunning, user-friendly websites tailored just for you—whether you’re a startup or an established brand. Let’s bring your vision to life!
                        </p>
                        <Button variant="primary" size="lg" href="#services" className="mt-3">Get Started</Button>
                    </Col>
                    <Col md={6} className="d-none d-md-block">
                        <div className="iframe-container">
                            <iframe src="https://lottie.host/embed/64079f0b-98a4-4398-9485-9e5a2ba2b9e2/BqcS5EkYF2.json" style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
                        </div>
                    </Col>
                </Row>
                <Row className="d-md-none mt-4">
                    <Col>
                        <div className="iframe-container">
                        <iframe src="https://lottie.host/embed/64079f0b-98a4-4398-9485-9e5a2ba2b9e2/BqcS5EkYF2.json" style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
                        </div>
                    </Col>
                </Row>
            </animated.div>

            <WhyYouNeed />

            <Row id="services" className="mt-5 d-flex align-items-stretch" ref={ref}>
                <h2 className="text-center services-title">Our Services</h2>
                {services.map((service, index) => {
                    const animation = Animation(index);
                    return (
                        <Col xs={12} md={6} lg={3} key={index} className="mb-4">
                            <animated.div style={animation} className="service-card h-100">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-${index}`}>{service.tooltip}</Tooltip>}
                                >
                                    <div className="text-center">
                                        <img
                                            src={service.icon}
                                            alt={`${service.title} icon`}
                                            className="icon-img mb-3"
                                        />
                                        <h3>{service.title}</h3>
                                        <p>{service.description}</p>
                                    </div>
                                </OverlayTrigger>
                            </animated.div>
                        </Col>
                    );
                })}
            </Row>

            <WhyUs />
            <HowWeOperate />
            <ContactUs />
        </Container>
    );
};

export default Home;
