import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home.js";
import Navbar from './pages/components/Navbar.js';
import Footer from './pages/components/footer.js';
import Attr from './pages/components/attr.js';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/attr' element={<Attr />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
