import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/navbar.css';

const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const navbarHeight = 200; // Adjust based on your navbar's height
    const sectionTop = section.getBoundingClientRect().top + window.scrollY - navbarHeight;
    window.scrollTo({ top: sectionTop, behavior: 'smooth' });
  }
};

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = (id) => {
    scrollToSection(id);
    setIsOpen(false);
  };

  return (
    <Navbar bg="light" expand="lg" className="navbar-custom sticky-navbar">
      <Navbar.Brand as={Link} to="/" className="brand">
        Crafted Web Creations
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsOpen(!isOpen)} />
      <Navbar.Collapse id="basic-navbar-nav" in={isOpen}>
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/" className="nav-link" onClick={() => handleLinkClick('home')}>Home</Nav.Link>
          <Nav.Link className="nav-link" onClick={() => handleLinkClick('why-you-need')}>Why You Need A Website</Nav.Link>
          <Nav.Link className="nav-link" onClick={() => handleLinkClick('services')}>Services</Nav.Link>
          <Nav.Link className="nav-link" onClick={() => handleLinkClick('why-us')}>Why Us</Nav.Link>
          <Nav.Link className="nav-link" onClick={() => handleLinkClick('how-we-operate')}>How We Operate</Nav.Link>
          <Nav.Link className="nav-link" onClick={() => handleLinkClick('contact-us')}>Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
