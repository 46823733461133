const Attr = () => {
    return(
        <div style={{textAlign: "center", marginTop: "20px"}}>
            <h1>
                Huge Thanks To The Following For Letting Us Use Their Artwork!
            </h1>
            <ul>
                <li><a href="https://www.flaticon.com/free-icons/counseling" title="counseling icons">Counseling icons created by spacepixel - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/source-code" title="source code icons">Source code icons created by Uniconlabs - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/price" title="price icons">Price icons created by Freepik - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/fast-delivery" title="fast delivery icons">Fast delivery icons created by Dewi Sari - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/website" title="website icons">Website icons created by Freepik - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/seo" title="seo icons">Seo icons created by Freepik - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/branding" title="branding icons">Branding icons created by Freepik - Flaticon</a></li>
                <li><a href="https://www.flaticon.com/free-icons/dedicated-hosting" title="dedicated hosting icons">Dedicated hosting icons created by juicy_fish - Flaticon</a></li>
            </ul>
        </div>
    );
};

export default Attr;